import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomWindow, whatFixURL } from './google-util.config';
@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  private isLoaded = false;
  private readonly gtmId: string;
  _window: CustomWindow;
  helpUrl: string;

  constructor(
    // private browserGlobals: BrowserGlobalsService,
    @Inject('googleTagManagerId') public googleTagManagerId: string,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly appStore: Store<any>
  ) {
    this.gtmId = googleTagManagerId;
    this._window = this._document.defaultView;
    this.subscribeHelpUrl();
  }

  subscribeHelpUrl(): void {
    this.appStore
      .select((states: any) => states.environment)
      .subscribe(response => {
        if (response) {
          this.helpUrl = response.helpUrl;
        }
      });
  }

  public getDataLayer() {
    this._window['dataLayer'] = this._window['dataLayer'] || [];
    return this._window['dataLayer'];
  }

  public pushOnDataLayer(obj: object) {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  public getContentViewLayer() {
    this._window['_uxa'] = this._window['_uxa'] || [];
    return this._window['_uxa'];
  }

  //User chooses not to be tracked
  public stopUXATracking() {
    const contentLayer = this.getContentViewLayer();
    contentLayer.push(['optout']);
  }

  //OptInt user again for content square tracking
  public startUXATracking() {
    if (document.cookie.indexOf('_cs_optout') !== -1) {
      document.cookie = '_cs_optout=; path=/; domain=.cat.com; expires=' + new Date(0).toUTCString();
    }
  }

  public pushContentView(obj: object) {
    const contentLayer = this.getContentViewLayer();
    const queryPrefix = obj['pageURL'] === undefined ? this._window.location.hash.replace('#', '') + '?' : '';
    contentLayer.push(['trackPageview', queryPrefix + obj['contentQuery']]);
  }

  //Checks if the same event is already pushed.
  //Cases where application calls the same method multiple times for actions
  public checkIfChangeTracked(url: string) {
    const winUxa = this.getContentViewLayer();
    const lastEntry = winUxa.length && winUxa.length > 1 ? winUxa[winUxa.length - 1] : winUxa[0];
    const lastTrackedView = lastEntry && lastEntry[0] === 'trackPageview' ? lastEntry[1] : null;
    return lastTrackedView === url ? true : false;
  }

  public addGtmToDom() {
    this.pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    this.insertGTM();
    this.insertGTMIFrame();
    this.isLoaded = true;
  }

  public addWhatFixToDom() {
    const appEnv = this.identifyAppEnv();
    if (appEnv !== 'local') {
    const env = this.mapWhatFixToAppEnv(appEnv);
    this.insertWhatFix(env);
    }
  }
  
  public removeWhatFix(): void {
    this.isLoaded = false;
    if (this._window['_wfx_destroy']) {
      this._window._wfx_destroy();
    }
  }

  private insertWhatFix(env: string) {
    // Based on WhatFix team input, commenting this - will be added again in next PR
    // if (this._document.querySelector('#WhatFixScript') !== null) {
    //   return;
    // }

    const scriptUrl = this.getWhatFixUrl();
    const whatFixScript = this._document.createElement('script');
    whatFixScript.setAttribute('crossorigin', 'anonymous');
    whatFixScript.setAttribute('type', 'text/javascript');
    whatFixScript.setAttribute('defer', 'true');
    whatFixScript.setAttribute('language', 'javascript');
    whatFixScript.setAttribute('id', 'WhatFixScript');
    whatFixScript.setAttribute('src', scriptUrl);
    this._document.head.insertBefore(whatFixScript, this._document.head.firstChild);
    this._window['_whatfixConfig'] = { smartLoad: true };
  }

  getWhatFixUrl(): string {
    return `${whatFixURL}`;
  }

  private insertGTM() {
    //if GTMscript already loaded, then return
    if (this._document.querySelector('#GTMscript') !== null) {
      return;
    }

    const gtmScript = this._document.createElement('script');
    gtmScript.setAttribute('crossorigin', 'anonymous');
    gtmScript.setAttribute('async', '');
    gtmScript.setAttribute('id', 'GTMscript');
    gtmScript.setAttribute('src', `//www.googletagmanager.com/gtm.js?id=${this.gtmId}`);
    this._document.head.insertBefore(gtmScript, this._document.head.firstChild);
  }

  private insertGTMIFrame() {
    //if GTMiframe already loaded, then return
    if (this._document.querySelector('#GTMiframe') !== null) {
      return;
    }
    if (this._window.location.origin !== this.helpUrl) {
      this.addFrameBusting();
    }
    const ifrm = this._document.createElement('iframe');
    ifrm.setAttribute('src', `//www.googletagmanager.com/ns.html?id=${this.gtmId}`);
    ifrm.style.width = '0';
    ifrm.style.height = '0';
    ifrm.style.display = 'none';
    ifrm.style.visibility = 'hidden';

    const noscript = this._document.createElement('noscript');
    noscript.id = 'GTMiframe';
    noscript.appendChild(ifrm);
    this._document.body.insertBefore(noscript, this._document.body.firstChild);
  }

  addFrameBusting(): void {
    if (this._window.top.location !== this._window.location) {
      this._window.top.location = this._window.location;
    }
  }
  mapWhatFixToAppEnv(appEnv: string): string {
    if (appEnv === 'dev' || appEnv === 'int') {
      return appEnv; // WalkMe Dev,INT mapped to app dev/dev-beta and INT respectively
    } else if (appEnv === 'qa' || appEnv === 'perf') {
      return 'test'; // WalkMe TEST - QA , QA- beta , Perf
    } else if (appEnv === 'stage' || appEnv === 'stg') {
      return 'stage'; // WalkMe Stage - Stage
    } else {
      return ''; //WalkMe Prod - PROD, PROD-beta
    }
  }

  // Identifying app environment
  identifyAppEnv(env?): string {
    const hostName = env || this._window.location.hostname;
    if (hostName.indexOf('local') >= 0) {
      return 'local';
    } else if (hostName.indexOf('-') !== -1 && hostName.split('-')[0] === 'beta') {
      return 'prod'; // slots prod
    } else if (hostName.indexOf('-') !== -1) {
      return hostName.split('-')[0]; // lower env
    } else {
      return 'prod';
    }
  }

  public pushTag(item: object) {
    if (!this.isLoaded) {
      this.addGtmToDom();
    }
    this.pushOnDataLayer(item);
  }
}
