import { IKeys } from '@Terra/shared/widgets/interface';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { GOOGLE_TAG_ACTIONS, GOOGLE_TAG_DATA, GoogleTagData, TAG_PROPERTIES, appLoadInfoURL } from './google-util.config';
@Injectable({
  providedIn: 'root'
})
export class GoogleUtilService implements OnDestroy {
  envObj: any;
  googleApiKeyVal: any;
  $googleApiKey = new Subject<any>();
  googleAPIKey = this.$googleApiKey.asObservable();
  instrumentationKeyVal: string | null = '';
  private instrumentationKey = new Subject<any>();
  instrumentationKey$ = this.instrumentationKey.asObservable();
  performanceCookie$ = new Subject<boolean>();
  subscriptions = new Subscription();

  isPerformanceCookieEnabled = false;
  pushDataToGoogleDataLayer = new Subject<string | GoogleTagData>();

  constructor(public store: Store<any>, public http: HttpClient, public gtmService: GoogleTagManagerService) {
    this.subscribeEnv();
    this.subscribeToPerformanceCookie();
    this.subscribeToGoogleDataPush();
  }

  subscribeToPerformanceCookie() {
    this.performanceCookie$.subscribe(res => {
      this.isPerformanceCookieEnabled = res;
    });
  }

  // Setting the observable;
  updateInstrumentationKey() {
    this.instrumentationKey.next(this.instrumentationKeyVal);
  }

  // Subscribe to Environment;
  subscribeEnv(): void {
    this.store
      .select((state: any) => state.environment)
      .subscribe(response => {
        if (response) {
          this.envObj = response;
        }
      });
  }

  // Fetch GoogleApi,instrumentation key and userinfo from node layer;
  getAppLoadInfo(): void {
    const getappLoadInfoURL = this.envObj.nodeBaseURL + appLoadInfoURL;
    const googleApiKeySubscription = this.http.get<IKeys>(getappLoadInfoURL).subscribe(res => {
      if (res) {
        this.$googleApiKey.next(res?.googleApiKey);
        this.googleApiKeyVal = res?.googleApiKey;
        this.instrumentationKeyVal = res?.instrumentationKey;
        this.updateInstrumentationKey();
      }
    });
    this.subscriptions.add(googleApiKeySubscription);
  }

  // Returns GoogleApi key to the Module/Component its called from;
  getGoogleAPIKey(): string | null {
    if (this.googleApiKeyVal) {
      return this.googleApiKeyVal;
    }
    return null;
  }

  // Should Subscribe to Google Data tagkey;
  subscribeToGoogleDataPush(): void {
    const pushDataGoogleDataLayer = this.pushDataToGoogleDataLayer.subscribe((tagKey: string | GoogleTagData) => {
      if (this.isPerformanceCookieEnabled && typeof tagKey === 'string') {
        this.pushTagInfoToDataLayer(GOOGLE_TAG_DATA[tagKey]);
      } else if (this.isPerformanceCookieEnabled && typeof tagKey === 'object') {
        this.pushTagInfoToDataLayer(tagKey);
      }
    });
    this.subscriptions.add(pushDataGoogleDataLayer);
  }

  //Push to Data Layer
  async pushTagInfoToDataLayer(eventData: GoogleTagData): Promise<void> {
    this.gtmService.pushOnDataLayer(
      Object.entries({
        ...eventData
      }).reduce(
        (obj, [key, value]) => ({
          ...obj,
          [key]: value === 'undefined' || value === '' ? null : value
        }),
        {}
      )
    );
  }

  setTableSortData(pagePath: string, formName: string): void {
    const sortOj: GoogleTagData = GOOGLE_TAG_DATA[GOOGLE_TAG_ACTIONS.tableSort];
    sortOj[TAG_PROPERTIES.pagePath] = pagePath;
    sortOj[TAG_PROPERTIES.formName] = formName;
    this.pushDataToGoogleDataLayer.next(sortOj);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
