export const googleApiKey = {
  apiKey: ''
};

export const whatFixURL = 'https://cdn.whatfix.com/prod/ab1fff7f-083c-4d86-99ce-12406c0fb864/initiator/initiator.nocache.js';

export const appLoadInfoURL = '/getAppLoadInfo';

export enum TAG_PROPERTIES {
  event = 'event',
  pagePath = 'pagePath',
  formName = 'formName',
  formContent = 'formContent',
  formContent2 = 'formContent2',
  formStatus = 'formStatus',
  formFieldCausingError = 'formFieldCausingError',
  formLocation = 'formLocation'
}

export const GOOGLE_TAG_ACTIONS = {
  helpCenter: 'helpCenter',
  changeKPI: 'changeKPI',
  dashboardMoreKPI: 'dashboardMoreKPI',
  dashboardSummary: 'dashboardSummary',
  productionAssetTag: 'productionAssetTag',
  costAssetTag: 'costAssetTag',
  utilizationAssetTag: 'utilizationAssetTag',
  productionKPI: 'productionKPI',
  utilizationKPI: 'utilizationKPI',
  costKPI: 'costKPI',
  productionDateRange: 'productionDateRange',
  utilizationDateRange: 'utilizationDateRange',
  costDateRange: 'costDateRange',
  assetViewProductionBoth: 'assetViewProductionBoth',
  assetViewCostBoth: 'assetViewCostBoth',
  assetViewUtilizationBoth: 'assetViewUtilizationBoth',
  assetViewProductionChart: 'assetViewProductionChart',
  assetViewCostChart: 'assetViewCostChart',
  assetViewUtilizationChart: 'assetViewUtilizationChart',
  assetViewProductionKPI: 'assetViewProductionKPI',
  assetViewCostKPI: 'assetViewCostKPI',
  assetViewUtilizationKPI: 'assetViewUtilizationKPI',
  assetViewProductionSelection: 'assetViewProductionSelection',
  assetViewCostSelection: 'assetViewCostSelection',
  assetViewUtilizationSelection: 'assetViewUtilizationSelection',
  assetViewProductionPlayback: 'assetViewProductionPlayback',
  assetViewCostPlayback: 'assetViewCostPlayback',
  assetViewUtilizationPlayback: 'assetViewUtilizationPlayback',
  assetViewProductionLocation: 'assetViewProductionLocation',
  assetViewCostLocation: 'assetViewCostLocation',
  assetViewUtilizationLocation: 'assetViewUtilizatioLocation',
  jobsiteUserPreferences: 'jobsiteUserPreferences',
  summaryAssetTag: 'summaryAssetTag',
  summaryViewBy: 'summaryViewBy',
  summaryDateRange: 'summaryDateRange',
  jobsiteChange: 'jobsiteChange',
  mapAssetTag: 'mapAssetTag',
  mapDateRange: 'mapDateRange',
  mapAsset: 'mapAsset',
  mapSiteImage: 'mapSiteImage',
  mapZone: 'mapZone',
  mapDownload: 'mapDownload',
  mapDownloadSurface: 'mapDownloadSurface',
  mapDownloadVeta: 'mapDownloadVeta',
  mapDownloadMachinePass: 'mapDownloadMachinePass',
  mapDetailViewCutFill: 'mapDetailViewCutFill',
  mapDetailViewElevation: 'mapDetailViewElevation',
  mapDetailViewCMV: 'mapDetailViewCMV',
  mapDetailViewMDP: 'mapDetailViewMDP',
  mapDetailViewPasscount: 'mapDetailViewPassCount',
  mapDetailViewSpeed: 'mapDetailViewSpeed',
  mapDetailViewTemp: 'mapDetailViewTemp',
  tableSort: 'tableSort',
  histogram: 'histogram'
};

export enum GOOGLE_TAG_VALUES {
  formSubmitted = 'formSubmitted',
  success = 'success'
}

const PAGE_PATH_CONSTANTS = {
  DASHBOARD_ACTIVESITE: '/dashboard/activesite/',
  DASHBOARD_SUMMARY: '/dashboard/summary/',
  DASHBOARD_INSIGHTS: '/dashboard/insights/'
};

export interface GoogleTagData {
  [TAG_PROPERTIES.event]: string;
  [TAG_PROPERTIES.pagePath]: string;
  [TAG_PROPERTIES.formName]: string;
  [TAG_PROPERTIES.formContent]: string;
  [TAG_PROPERTIES.formContent2]: string;
  [TAG_PROPERTIES.formStatus]: string;
  [TAG_PROPERTIES.formFieldCausingError]: string | null;
  [TAG_PROPERTIES.formLocation]: string;
}

export const GOOGLE_TAG_DATA = {
  [GOOGLE_TAG_ACTIONS.helpCenter]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_ACTIVESITE,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-HelpCenter',
    [TAG_PROPERTIES.formContent]: 'Help Center Click',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Help Center'
  },
  [GOOGLE_TAG_ACTIONS.changeKPI]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_ACTIVESITE,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-ChangeKPI',
    [TAG_PROPERTIES.formContent]: 'Change KPI clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Change KPI'
  },
  [GOOGLE_TAG_ACTIONS.dashboardSummary]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-DashboardSummary',
    [TAG_PROPERTIES.formContent]: 'Dashboard Summary clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Dashboard Summary'
  },
  [GOOGLE_TAG_ACTIONS.dashboardMoreKPI]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-DashboardMoreKPI',
    [TAG_PROPERTIES.formContent]: 'Dashboard More KPI clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Dashboard More KPI'
  },
  [GOOGLE_TAG_ACTIONS.productionAssetTag]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Production-Assettag',
    [TAG_PROPERTIES.formContent]: 'Production Asset tag clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Production Asset Tag'
  },
  [GOOGLE_TAG_ACTIONS.utilizationAssetTag]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Utilization-Assettag',
    [TAG_PROPERTIES.formContent]: 'Utilization Asset tag clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Utilization Asset Tag'
  },
  [GOOGLE_TAG_ACTIONS.costAssetTag]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Cost-Assettag',
    [TAG_PROPERTIES.formContent]: 'Cost Asset tag clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Cost Asset Tag'
  },
  [GOOGLE_TAG_ACTIONS.productionKPI]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Production-KPI',
    [TAG_PROPERTIES.formContent]: 'Production KPI clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Production KPI'
  },
  [GOOGLE_TAG_ACTIONS.utilizationKPI]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Utilization-KPI',
    [TAG_PROPERTIES.formContent]: 'Utilization KPI clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Utilization KPI'
  },
  [GOOGLE_TAG_ACTIONS.costKPI]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Cost-KPI',
    [TAG_PROPERTIES.formContent]: 'Cost KPI clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Cost KPI'
  },
  [GOOGLE_TAG_ACTIONS.productionDateRange]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Production-Daterange',
    [TAG_PROPERTIES.formContent]: 'Production Date Range clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Production Date Range'
  },
  [GOOGLE_TAG_ACTIONS.utilizationDateRange]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Utilization-Daterange',
    [TAG_PROPERTIES.formContent]: 'Utilization Date Range clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Utilization KPI'
  },
  [GOOGLE_TAG_ACTIONS.costDateRange]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Cost-Daterange',
    [TAG_PROPERTIES.formContent]: 'Cost Date Range clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Cost Date Range'
  },
  [GOOGLE_TAG_ACTIONS.assetViewProductionBoth]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Production-Assetview-Both',
    [TAG_PROPERTIES.formContent]: 'Asset View Both clicked in Production',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Both Production'
  },
  [GOOGLE_TAG_ACTIONS.assetViewCostBoth]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Cost-Assetview-Both',
    [TAG_PROPERTIES.formContent]: 'Asset View Both clicked in Cost',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Both Cost'
  },
  [GOOGLE_TAG_ACTIONS.assetViewUtilizationBoth]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Utilization-Assetview-Both',
    [TAG_PROPERTIES.formContent]: 'Asset View Both clicked in Utilization',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Both Utilization'
  },
  [GOOGLE_TAG_ACTIONS.assetViewProductionChart]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Production-Assetview-Chart',
    [TAG_PROPERTIES.formContent]: 'Asset View Chart clicked in Production',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Chart Production'
  },
  [GOOGLE_TAG_ACTIONS.assetViewCostChart]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Cost-Assetview-Chart',
    [TAG_PROPERTIES.formContent]: 'Asset View Chart clicked in Cost',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Chart Cost'
  },
  [GOOGLE_TAG_ACTIONS.assetViewUtilizationChart]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Utilization-Assetview-Chart',
    [TAG_PROPERTIES.formContent]: 'Asset View Chart clicked in Utilization',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Chart Utilization'
  },
  [GOOGLE_TAG_ACTIONS.assetViewProductionKPI]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Production-Assetview-KPI',
    [TAG_PROPERTIES.formContent]: 'Asset View KPI clicked in Production',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View KPI Production'
  },
  [GOOGLE_TAG_ACTIONS.assetViewUtilizationKPI]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Utilization-Assetview-KPI',
    [TAG_PROPERTIES.formContent]: 'Asset View KPI clicked in Utilization',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View KPI Utilization'
  },
  [GOOGLE_TAG_ACTIONS.assetViewCostKPI]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Cost-Assetview-KPI',
    [TAG_PROPERTIES.formContent]: 'Asset View KPI clicked in Cost',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View KPI Cost'
  },
  [GOOGLE_TAG_ACTIONS.assetViewProductionSelection]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Production-Assetview-Assetselection',
    [TAG_PROPERTIES.formContent]: 'Asset View Asset Selection clicked in Production',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Asset Selection in Production'
  },
  [GOOGLE_TAG_ACTIONS.assetViewCostSelection]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Cost-Assetview-Assetselection',
    [TAG_PROPERTIES.formContent]: 'Asset View Asset Selection clicked in Cost',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Asset Selection in Cost'
  },
  [GOOGLE_TAG_ACTIONS.assetViewUtilizationSelection]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Utilization-Assetview-Assetselection',
    [TAG_PROPERTIES.formContent]: 'Asset View Asset Selection clicked in Utilization',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Asset Selection in Utilization'
  },
  [GOOGLE_TAG_ACTIONS.jobsiteUserPreferences]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-UserPreference',
    [TAG_PROPERTIES.formContent]: 'Jobsite User Preference clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Jobsite User Preference'
  },
  [GOOGLE_TAG_ACTIONS.assetViewProductionPlayback]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Production-Assetview-Playback',
    [TAG_PROPERTIES.formContent]: 'Asset View Playback clicked in Production',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Playback in Production'
  },
  [GOOGLE_TAG_ACTIONS.assetViewCostPlayback]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Cost-Assetview-Playback',
    [TAG_PROPERTIES.formContent]: 'Asset View Playback clicked in Cost',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Playback in Cost'
  },
  [GOOGLE_TAG_ACTIONS.assetViewUtilizationPlayback]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Utilization-Assetview-Playback',
    [TAG_PROPERTIES.formContent]: 'Asset View Playback clicked in Utilization',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Playback in Utilization'
  },
  [GOOGLE_TAG_ACTIONS.assetViewProductionLocation]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Production-Assetview-Assetlocation',
    [TAG_PROPERTIES.formContent]: 'Asset View Asset Location clicked in Production',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Asset Location in Production'
  },
  [GOOGLE_TAG_ACTIONS.assetViewCostLocation]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Cost-Assetview-Assetlocation',
    [TAG_PROPERTIES.formContent]: 'Asset View Asset Location clicked in Cost',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Asset Location in Cost'
  },
  [GOOGLE_TAG_ACTIONS.assetViewUtilizationLocation]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Utilization-Assetview-Assetlocation',
    [TAG_PROPERTIES.formContent]: 'Asset View Asset Location clicked in Utilization',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Asset View Asset Location in Utilization'
  },
  [GOOGLE_TAG_ACTIONS.summaryAssetTag]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Summary-Assettag',
    [TAG_PROPERTIES.formContent]: 'Summary Asset Tag clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Summary Asset tag'
  },
  [GOOGLE_TAG_ACTIONS.summaryDateRange]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Summary-DateRange',
    [TAG_PROPERTIES.formContent]: 'Summary Date Range clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Summary Date Range'
  },
  [GOOGLE_TAG_ACTIONS.summaryViewBy]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Summary-ViewBy',
    [TAG_PROPERTIES.formContent]: 'Summary View By clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Summary View By'
  },
  [GOOGLE_TAG_ACTIONS.jobsiteChange]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-JobsiteChange',
    [TAG_PROPERTIES.formContent]: 'Jobsite Change clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Jobsite Change'
  },
  [GOOGLE_TAG_ACTIONS.mapAsset]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-Asset',
    [TAG_PROPERTIES.formContent]: 'Map Assets clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map Assets'
  },
  [GOOGLE_TAG_ACTIONS.mapAssetTag]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-Assettag',
    [TAG_PROPERTIES.formContent]: 'Map Assettag clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map Assettag'
  },
  [GOOGLE_TAG_ACTIONS.mapDateRange]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-Daterange',
    [TAG_PROPERTIES.formContent]: 'Map Daterange clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map Daterange'
  },
  [GOOGLE_TAG_ACTIONS.mapSiteImage]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-SiteImage',
    [TAG_PROPERTIES.formContent]: 'Map SiteImage clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map SiteImage'
  },
  [GOOGLE_TAG_ACTIONS.mapZone]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-Zones',
    [TAG_PROPERTIES.formContent]: 'Map Zones clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map Zones'
  },
  [GOOGLE_TAG_ACTIONS.mapDownload]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-Download',
    [TAG_PROPERTIES.formContent]: 'Map Download clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map Download'
  },
  [GOOGLE_TAG_ACTIONS.mapDownloadSurface]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-Download-Surface',
    [TAG_PROPERTIES.formContent]: 'Map Download Surface clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map Download Surface'
  },
  [GOOGLE_TAG_ACTIONS.mapDownloadVeta]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-Download-Veta',
    [TAG_PROPERTIES.formContent]: 'Map Download Veta clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map Download Veta'
  },
  [GOOGLE_TAG_ACTIONS.mapDownloadMachinePass]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-Download-MachinePass',
    [TAG_PROPERTIES.formContent]: 'Map Download MachinePass clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map Download MachinePass'
  },
  [GOOGLE_TAG_ACTIONS.mapDetailViewCutFill]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-CutFill',
    [TAG_PROPERTIES.formContent]: 'Map MapdetailView CutFill clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map CutFill'
  },
  [GOOGLE_TAG_ACTIONS.mapDetailViewElevation]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-MapdetailView-Elevation',
    [TAG_PROPERTIES.formContent]: 'Map MapdetailView Elevation clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map MapdetailView Elevation'
  },
  [GOOGLE_TAG_ACTIONS.mapDetailViewCMV]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-MapdetailView-CMV',
    [TAG_PROPERTIES.formContent]: 'Map MapdetailView CMV clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map MapdetailView CMV'
  },
  [GOOGLE_TAG_ACTIONS.mapDetailViewMDP]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-MapdetailView-MDP',
    [TAG_PROPERTIES.formContent]: 'Map MapdetailView MDP clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map MapdetailView MDP'
  },
  [GOOGLE_TAG_ACTIONS.mapDetailViewPasscount]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-MapdetailView-Passcount',
    [TAG_PROPERTIES.formContent]: 'Map MapdetailView Passcount clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map MapdetailView Passcount'
  },
  [GOOGLE_TAG_ACTIONS.mapDetailViewSpeed]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-MapdetailView-Speed',
    [TAG_PROPERTIES.formContent]: 'Map MapdetailView Speed clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map MapdetailView Speed'
  },
  [GOOGLE_TAG_ACTIONS.mapDetailViewTemp]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_INSIGHTS,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-Map-MapdetailView-Temp',
    [TAG_PROPERTIES.formContent]: 'Map MapdetailView Temp clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Map MapdetailView Temp'
  },
  [GOOGLE_TAG_ACTIONS.tableSort]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: '/dashboard/activesite/', //
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-ChangeKPI', //
    [TAG_PROPERTIES.formContent]: 'Table Sorting',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Table Sorting'
  },
  [GOOGLE_TAG_ACTIONS.histogram]: {
    [TAG_PROPERTIES.event]: GOOGLE_TAG_VALUES.formSubmitted,
    [TAG_PROPERTIES.pagePath]: PAGE_PATH_CONSTANTS.DASHBOARD_SUMMARY,
    [TAG_PROPERTIES.formName]: 'VisionlinkProductivity-SD_Histogram',
    [TAG_PROPERTIES.formContent]: 'Histogram clicked',
    [TAG_PROPERTIES.formContent2]: '',
    [TAG_PROPERTIES.formStatus]: GOOGLE_TAG_VALUES.success,
    [TAG_PROPERTIES.formFieldCausingError]: null,
    [TAG_PROPERTIES.formLocation]: 'Histogram'
  }
};

export interface CustomWindow extends Window {
  _wfx_destroy?(): void;
}